<template>
  <div class="make-box">
    <div class="make-box-header" v-if="$route.query.id">
      <el-button type="primary" @click="goBack">返回</el-button>
    </div>
    <el-scrollbar class="jd-enter-scrollbar" :native="false">
      <div class="jd-business-box">
        <div class="jd-business-header">
          <div class="header-img">
            <img src="./../../../../assets/images/student/JD-logo.png" alt="">
          </div>
          <div class="header-title">
            京东主站业务
          </div>
        </div>
        <div class="jd-business-explain">
          <span>支持业务说明:</span>
          <span>POP主站：在国内进行销售的商家。</span>
          <span>奢侈品店：仅支持国内外奢侈品品牌。</span>
          <span>拍卖店铺：参加拍卖业务的拍卖公司。</span>
        </div>
        <div class="jd-business-request">
          <span>普通商家入驻要求:</span>
          <span>1、请确保您的企业营业执照、组织机构代码证、银行开户许可证、税务登记证、一般纳税人资格证均有效；</span>
          <span>2、请确保您所拥有的品牌有效期或已获得相关授权；</span>
          <span>3、请确保您所售商品已取得国家规定的相关行业资质。</span>
          <span>若需联系各类目招商，请进入 zhaoshang.jd.com/p/contact/list？menuld=3</span>
          <span>更多详细的规则，请进入rule.jd.com/rule/ruleDetall.action？ruleld=2368</span>
        </div>
        <div class="jd-business-footer">
          <el-button type="primary" @click="dialogVisible = true">入驻京东主站</el-button>
          <el-button style="margin-left: 20px" plain @click="openFileDownload">素材下载</el-button>
        </div>
      </div>
    </el-scrollbar>
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        class="custom-dialog"
        title="提示信息"
        :visible.sync="dialogVisible"
        width="500px">
      <div class="dialog-content">
        <span style="color: #666666">您即将申请国内POP商家入驻，请确认并准备您企业获得的资质：</span>
        <span>1、企业营业执照、组织机构代码证、税务登记证、银行开户行许可证、企业法人身份证</span>
        <span>2、经营商品所属行业相关资质证明</span>
        <span>3、经营商品所属品牌授权相关证明</span>
        <span style="color: #666666">如已符合以上几点，请点击继续入驻。</span>

      </div>
      <div class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" style="margin-left: 20px" @click="toEnter">确认，继续入驻</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "SettledJD",
  data(){
    return {
      fileDownload:localStorage.getItem('file_download') || null,
      dialogVisible:false,
    }
  },
  methods:{
    openFileDownload(){
      window.open(this.fileDownload, '_blank')
    },
    toEnter(){
      if(this.$route.query.id){
        this.$router.push({
          path:'/student/trainCenter/startTraining/storeSignAndSet',
          query:{
            id:this.$route.query.id,
            course_id:this.$route.query.course_id
          }
        })
      } else {
        this.$router.push('/student/examing/onlineShopDecoration/storeSignAndSet')
      }
    },
    goBack(){
      this.$router.go(-1)
    }
  }
}
</script>

<style scoped lang="scss">
.make-box{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .make-box-header{
    text-align: right;
    padding-right: 40px;
  }
  .jd-enter-scrollbar{
    height: 1%;
    flex: 1;
    display: flex;
    flex-direction: column;
    ::v-deep .el-scrollbar__wrap{
      overflow-x: hidden;
    }
    .jd-business-box{
      width: 720px;
      box-shadow: 0px 0px 16px 0px rgba(43, 43, 43, 0.09);
      margin: 90px auto;
      display: flex;
      flex-direction: column;
      padding: 40px 30px 40px 40px;
      box-sizing: border-box;
      .jd-business-header{
        display: flex;
        flex-direction: column;
        align-items: center;
        .header-img{
          width: 182px;
          height: 56px;
          img{
            max-width: 100%;
            max-height: 100%;
          }
        }
        .header-title{
          font-size: 20px;
          color: #333;
          line-height: 1;
          padding: 40px 0 24px;
          font-weight: 500;
        }
      }
      .jd-business-explain, .jd-business-request{
        display: flex;
        flex-direction: column;
        span{
          line-height: 36px;
          font-size: 16px;
        }
        &.jd-business-request{
          margin-top: 40px;
        }
      }
      .jd-business-footer{
        margin-top: 30px;
        text-align: center;
      }
    }
  }
}
::v-deep .custom-dialog{
  .dialog-content{
    display: flex;
    flex-direction: column;
    span{
      font-size: 14px;
      color: #333;
      line-height: 24px;
      margin-top: 8px;
      &:first-of-type{
        margin-top: 0;
      }
    }
  }
  .dialog-footer{
    margin-top: 26px;
  }
}
</style>